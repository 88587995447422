body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    background: #FFFFFF;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


div::-webkit-scrollbar, ::-webkit-scrollbar {
    width: 8px;
}

body::-webkit-scrollbar {
    width: 15px;
}

body::-webkit-scrollbar-track, div::-webkit-scrollbar-track, ::-webkit-scrollbar-track {
    background: rgba(199, 199, 199, 0.12);
    border-radius: 15px;
    position: absolute;
    left: 0px;
}

body::-webkit-scrollbar-thumb, div::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb {
    background-color: #7db0e2;
    border-radius: 15px;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

html {
    box-sizing: border-box;
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

h1 {
    font-family: 'Open sans', sans-serif !important;
    font-weight: 600  !important;
    color: var(--midnight-blue)  !important;
    font-size: 2em  !important;
}

h2 {
    font-family: 'Open sans', sans-serif  !important;
    font-weight: 600 !important;
}


:root {
    --midnight-blue-dark-shade: #2D2C45;
    --midnight-blue: #2B295E;
    --midnight-blue-mid-shade: #C4C2F2;
    --midnight-blue-light-shade: #F0F0FF;

    --blue-haze-dark-shade: #26415C;
    --blue-haze: #70A7DB;
    --blue-haze-mid-shade: #B3D4F2;
    --blue-haze-light-shade: #E8F4FF;

    --pink-dark-shade: #591542;
    --pink: #BF087F;
    --pink-mid-shade: #F2B3DC;
    --pink-light-shade: #FFF0FA;

    --greys-black: #1A1A1A;
    --greys-dark-shade: #4D4D4D;
    --greys-mid-shade: #B3B3B3;
    --greys-light-shade: #F2F2F2;

    --black: #000000 ;
    --transparent : rgba(0, 0, 0, 0);
    --white:#FFFFFF;

}
