/*Loading*/
.sc-dkPtRN,
.sc-dkzDqf {
    color: var(--blue-haze) !important;
}

.sc-gsDKAQ > path,
.sc-gsnTZi > path {
    display: none;
}

.sc-gsDKAQ,
.sc-gsnTZi {
    animation: none !important;
    background-image: url(PayGuard-Animation.gif);
    width: 100px !important;
    height: 100px !important;
    margin-left: -25px !important;

}
/*General*/

/*Left block*/
/*logo*/
.sc-ctqQKy,
.sc-csvncw {
    max-height: 180px !important;
    max-width: 180px !important;
    margin-right: auto !important;
    margin-left: auto !important;
}

/*text*/
[role=menuitem] {
    font-family: 'Open sans', sans-serif !important;
    font-size: 13px !important;
    font-weight: 500 !important;
}

/*text*/
[role=menuitem][type=group] {
    text-transform: none !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    color: var(--black);
}

/*Main block*/
[id="section/Authentication/apiKey"] {
    padding: 0 !important;
}

/*Right block*/

/* request Url*/
.sc-kYHfwS,
.sc-kYWVYA{
    margin-block-start: 0.9em !important;
    margin-block-end: 0.9em !important;
    border-radius:  10px!important;
}

.sc-dVNjXY,
.sc-dWINGa {
    background: var(--white) !important;
    border-color: var(--white) !important;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 5px, rgba(0, 0, 0, 0.11) 0px 2px 5px !important;
    border-radius:  10px!important;
}

.gkxVWg,
.hVuyKw{
    border-radius:  10px 10px 0 0!important;
}
/*div*/
.sc-bQtKYq,
.sc-bPyhqo {
    border-radius: 0 0 10px 10px!important;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 5px, rgba(0, 0, 0, 0.11) 0px 2px 5px !important;
    position: static !important;
}
/*div with url*/
.sc-FNXRL,
.sc-ESujJ{
    border: none !important;
}


/*placing "Content type"*/
.sc-nVkyK,
.sc-olbas {
    display: inline !important;
    position: static !important;
    margin-left: 10px !important;
    font-size: 13px !important;
}

/*application/json*/
.sc-eJwWfJ,
.sc-eKszNL {
    display: inline !important;
    position: static !important;
    background: var(--transparent) !important;
    font-size: 13px !important;
}

/*Button : Copy, Expand all, Collapse all */
.sc-efQSVx > button,
.sc-efBctP > button {
    font-weight: 600 !important;
}

.sc-efQSVx > button:hover,
.sc-efBctP > button:hover {
    color: var(--blue-haze) !important;
}

/*color text in code*/
.token {
    color: var(--greys-black) !important;
}

/*Response samples code*/
.react-tabs__tab--selected {
    border: 1px solid var(--blue-haze-mid-shade) !important;
    background: var(--white) !important;
    border-radius: 10px !important;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 5px, rgba(0, 0, 0, 0.11) 0px 2px 5px;
    margin-bottom: 6px !important;
}

/*Response samples */
.react-tabs__tab-panel {
    background: var(--white) !important;
    border-radius: 10px !important;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 5px, rgba(0, 0, 0, 0.11) 0px 2px 5px;
    margin-bottom: 6px !important;
}

.sc-gGCDDS,
.sc-gFGZVQ {
    border: 1px solid var(--blue-haze-mid-shade) !important;
    background: var(--blue-haze-light-shade) !important;
    border-radius: 10px !important;
    padding: 10px !important;
}

ul.list {
    padding-left: 40px;
}

.iwKnRo,
.xyRVC,
.blAWgM,
.bWpJce {
    border-radius: 10px !important;
    background: var(--blue-haze-light-shade) !important;
}

.http-verb {
    border-radius: 5px !important;
}

.collapser{
    color: var(--black) !important;
}